<template>
  <v-container class="py-0 py-sm-8 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-2">
        <v-card class="d-flex align-center font-weight-bold justify-center pa-4 fill-width fill-height secondary" dark outlined @click.prevent="ACCOUNT_SHOW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Tambah Data
        </v-card>
      </v-col>
      <v-col cols="6" class="col-sm-1">
        <XlsxImport @reloadTable="ACCOUNTS_GET(tableSearch)" />
      </v-col>
      <v-col cols="6" class="col-sm-1">
        <XlsxExport :search="tableSearch" :status="tableSearchStatus" :coordinator="tableSearchCoordinator" :range-dates="GetDates(dates)" :sort-by="options.sortBy ? options.sortBy[0] : 'name'" :sort="options.sortDesc ? (options.sortDesc[0] ? 'DESC' : 'ASC') : 'ASC'" />
      </v-col>
      <v-col cols="12" class="col-sm-2 white">
        <div class="pl-4 pl-sm-8 pr-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class=""
            placeholder="Nama, nik, passport, telepon ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="6" class="col-sm-2 white">
        <div class="px-2 pt-4 pb-0 pb-sm-4">
          <v-menu
            ref="menu"
            v-model="menu"
            :return-value.sync="dates"
            left
            offset-y
            :close-on-content-click="false"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateRangeText"
                label="Date Ranges"
                append-icon="mdi-calendar-outline"
                readonly
                outlined
                dense
                hide-details
                clearable
                class="rounded-0"
                v-bind="attrs"
                v-on="on"
                @click:clear="dates = [], ACCOUNTS_GET(tableSearch)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              light
              class="force-light-font"
              range
            >
              <v-spacer />
              <v-btn
                text
                class="text-capitalize"
                small
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                class="text-capitalize"
                small
                @click="$refs.menu.save(dates), (dates.length === 2 ? ACCOUNTS_GET(tableSearch) : '')"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-2 white">
        <div class="px-4 pt-4 pb-4">
          <v-select
            v-model.number="tableSearchCoordinator"
            :items="[{ id: 0, name: 'Semua Koordinator' }].concat($store.state.coordinators)"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            class=""
            label="Pilih Koordinator"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-2 white">
        <div class="px-4 pt-4 pb-4">
          <v-select
            v-model.number="tableSearchStatus"
            :items="[
              { name: 'Semua Data', value: -1 },
              { name: 'Undefined', value: 0 },
              { name: 'Sedang Berada di Luar Negeri', value: 1 },
              { name: 'Berada di Indonesia', value: 2 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class=""
            label="Status Keberadaan"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div>
              <div class="d-flex align-center text-uppercase">
                <v-icon class="mr-1">
                  mdi-barcode
                </v-icon>
                <span class="font-weight-bold caption">
                  KBRIKL/{{ ('000000' + item.id).slice(-6) }}
                </span>
              </div>
              <div class=caption>
                {{ $localDT(item.created, 'datetimedefault') }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex flex-column py-2">
              <div class="d-flex fill-width align-center justify-space-between text-capitalize">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
                <v-chip x-small>
                  NIK: {{ item.nik || '-' }}
                </v-chip>
              </div>
              <div>
                {{ item.phone_code }}{{ item.phone }}, {{ item.email || '-' }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.stay_reason`]="{ item }">
            <div class="py-2">
              {{ item.stay_reason }}
              <div>
                {{ item.stay_name || '-' }}, {{ item.stay_data1 || '-' }}, {{ item.stay_data2 || '-' }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.passport_expire`]="{ item }">
            <div class="py-2">
              No. {{ item.passport || '-' }}
              <div>
                Expired at {{ $localDT(item.passport_expire, 'displaylocal') }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.visa_validity_to`]="{ item }">
            <div class="py-2">
              No. {{ item.visa || '-' }}
              <div>
                Validity :
                <span v-if="item.visa_validity_from && item.visa_validity_to">{{ $localDT(item.visa_validity_from, 'displaylocal') }} to {{ $localDT(item.visa_validity_to, 'displaylocal') }}</span>
                <span v-else>-</span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.coordinator_name`]="{ item }">
            <div v-if="item.coordinator_name" class="text-left" style="min-width: 100px;">
              <strong>{{ item.coordinator_name.split(',')[0] }}</strong>
              <div class="caption" style="line-height: 130%;">
                {{ item.coordinator_name.split(',')[1] }}<br>
                {{ item.coordinator_name.split(',')[2] }}
              </div>
            </div>
            <div v-else class="text-center" style="min-width: 100px;">
              '-'
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.status) === 2 ? 'green darken-2' : parseInt(item.status) === 1 ? 'blue darken-2' : 'red'" outlined dark small>
                {{ parseInt(item.status) === 2 ? 'Berada di Indonesia' : parseInt(item.status) === 1 ? 'Sedang Berada di Luar Negeri' : '-' }}
              </v-chip>
              <!-- { name: 'Undefined', value: 0 },
              { name: 'Sedang Berada di Luar Negeri', value: 1 },
              { name: 'Berada di Indonesia', value: 2 } -->
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager'], $store.state.user.role)" depressed small dark color="orange" class="text-capitalize" @click.prevent="ACCOUNT_SHOW(item)">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
              <v-btn text small dark color="red" class="text-capitalize" @click.prevent="DeleteCustomer(item)"
              >
                Hapus
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <div class="pt-2 pb-2 px-4 px-md-8 white fill-width">
              <v-divider class="mt-2 mb-3" />
              <div class="d-flex justify-end flex-column flex-sm-row">
                <div class="d-flex align-center font-weight-bold">
                  Total : {{ tableTotal }}
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogAccount.show"
      persistent
      scrollable
      max-width="720"
    >
      <v-card v-if="dialogAccount.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogAccount.data.id ? 'Update Data' : 'Tambah Data Baru' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: calc(100vh - 200px)">
          <div class="d-flex fill-width justify-center pt-4 pb-8">
            <v-img
              :src="dialogAccount.data.img || '/logo.png'"
              :lazy-src="dialogAccount.data.img || '/logo.png'"
              :aspect-ratio="1"
              max-width="100"
              class="rounded-circle grey lighten-4 mr-4"
            />
          </div>
          <v-select
            v-model.number="dialogAccount.data.status"
            :items="[
              { name: 'Undefined', value: 0 },
              { name: 'Sedang Berada di Luar Negeri', value: 1 },
              { name: 'Berada di Indonesia', value: 2 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class=""
            label="Status Saat Ini"
          />
          <v-divider class="my-4" />
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-6 pr-0 pr-sm-2">
              <v-text-field
                v-model="dialogAccount.data.name"
                outlined
                dense
                hide-details
                label="Nama Lengkap (*)"
                class="mb-4 "
              />
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <v-text-field
                    v-model="dialogAccount.data.birthday_place"
                    outlined
                    dense
                    hide-details
                    label="Tempat Lahir (*)"
                    class="mb-4 "
                  />
                </v-col>
                <v-col cols="6" class="pl-2">
                  <v-menu
                    v-if="false"
                    ref="menuDateBirthday"
                    v-model="menuDateBirthday"
                    :close-on-content-click="false"
                    :return-value.sync="dialogAccount.data.birthday_date"
                    transition="slide-x-reverse-transition"
                    bottom
                    left
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="dialogAccount.data.birthday_date ? (dialogAccount.data.birthday_date.split('-').length ? $ConvertExcelDate(dialogAccount.data.birthday_date) : $ConvertExcelDate(dialogAccount.data.birthday_date)) : dialogAccount.data.birthday_date"
                        outlined
                        dense
                        hide-details
                        label="Tanggal Lahir (dd/mm/yyyy)"
                        class="mb-4"
                        v-bind="attrs"
                        v-on="on"
                        @keyup="!$event.target.value ? '' : ($event.target.value.length === 10 ? $refs.menuDateBirthday.save($ConvertExcelDate($event.target.value)) : '')"
                      />
                    </template>
                    <v-date-picker
                      v-model="dialogAccount.data.birthday_date"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        class="text-capitalize"
                        @click="menuDateBirthday = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        class="text-capitalize"
                        @click="$refs.menuDateBirthday.save(dialogAccount.data.birthday_date)"
                      >
                        Ok
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-text-field
                    v-model="dialogAccount.data.birthday_date"
                    placeholder="DD/MM/YYYY"
                    outlined
                    dense
                    hide-details
                    label="Tanggal Lahir (DD/MM/YYYY) (*)"
                    class="mb-4"
                  />
                </v-col>
              </v-row>
              <v-text-field
                v-model="dialogAccount.data.nik"
                outlined
                dense
                hide-details
                label="NIK/KTP (*)"
                class="mb-4 "
              />
              <v-text-field
                v-model="dialogAccount.data.passport"
                outlined
                dense
                hide-details
                label="No Passport (*)"
                class="mb-4 "
              />
              <v-menu
                v-if="false"
                ref="menuDatePassportExpire"
                v-model="menuDatePassportExpire"
                :close-on-content-click="false"
                :return-value.sync="dialogAccount.data.passport_expire"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dialogAccount.data.passport_expire ? (dialogAccount.data.passport_expire.split('-').length ? $ConvertExcelDate(dialogAccount.data.passport_expire) : $ConvertExcelDate(dialogAccount.data.passport_expire)) : dialogAccount.data.passport_expire"
                    outlined
                    dense
                    hide-details
                    label="Passport Expire (dd/mm/yyyy)"
                    class="mb-4"
                    v-bind="attrs"
                    v-on="on"
                    @keyup="!$event.target.value ? '' : ($event.target.value.length === 10 ? $refs.menuDatePassportExpire.save($ConvertExcelDate($event.target.value)) : '')"
                  />
                </template>
                <v-date-picker
                  v-model="dialogAccount.data.passport_expire"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    class="text-capitalize"
                    @click="menuDatePassportExpire = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    class="text-capitalize"
                    @click="$refs.menuDatePassportExpire.save(dialogAccount.data.passport_expire)"
                  >
                    Ok
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-text-field
                v-model="dialogAccount.data.passport_expire"
                placeholder="DD/MM/YYYY"
                outlined
                dense
                hide-details
                label="Passport Expire"
                class="mb-4"
              />

              <v-text-field
                v-model="dialogAccount.data.email"
                outlined
                dense
                hide-details
                label="Email"
                class="mb-4 "
              />
              <div class="d-flex fill-width mb-6">
                <v-autocomplete
                  v-model="dialogAccount.data.phone_code"
                  :items="$phoneCode()"
                  item-value="dial_code"
                  item-text="dial_code"
                  label="+Dial"
                  dense
                  outlined
                  color="secondary"
                  hide-details
                  class="mr-2 "
                  style="min-width:100px;max-width:100px;width:80px;"
                >
                  <template #item="d">
                    <v-list-item-content>
                      <v-list-item-title>{{ d.item.dial_code + ' ' + d.item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-model.number="dialogAccount.data.phone"
                  label="No Telepon (*)"
                  dense
                  outlined
                  color="secondary"
                  hide-details
                  class=""
                  @keypress="$NumberOnly"
                  @keyup="dialogAccount.data.phone = (parseInt(dialogAccount.data.phone || '') || '')"
                />
              </div>
              <v-textarea
                v-model="dialogAccount.data.address"
                outlined
                rows="4"
                dense
                hide-details
                label="Alamat Tinggal (KTP)"
                class="mb-4 "
              />
              <v-select
                v-model="dialogAccount.data.marriage_status"
                :items="[
                  { name: 'Kawin', value: 'Kawin' },
                  { name: 'Belum Kawin', value: 'Belum Kawin' },
                  { name: 'Cerai Hidup', value: 'Cerai Hidup' },
                  { name: 'Cerai Mati', value: 'Cerai Mati' }
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4 "
                label="Status Perkawinan"
              />
              <v-select
                v-model="dialogAccount.data.sex"
                :items="[
                  { name: 'Laki-laki', value: 'Laki-laki' },
                  { name: 'Perempuan', value: 'Perempuan' }
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4 "
                label="Jenis Kelamin"
              />
              <v-select
                v-model="dialogAccount.data.religion"
                :items="[
                  { name: 'Islam', value: 'Islam' },
                  { name: 'Kristen', value: 'Kristen' },
                  { name: 'Khatolik', value: 'Katholik' },
                  { name: 'Budha', value: 'Budha' },
                  { name: 'Hindu', value: 'Hindu' },
                  { name: 'Konghuchu', value: 'Konghuchu' },
                  { name: 'Kepercayaan', value: 'Kepercayaan' },
                  { name: 'Lainnya', value: 'Lainnya' }
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4 mb-sm-0"
                label="Agama"
              />
            </v-col>
            <v-col cols="12" class="col-sm-6 pl-0 pl-sm-2">
              <v-autocomplete
                v-model.number="dialogAccount.data.ln_country_id"
                :items="$phoneCode()"
                item-value="country_code"
                item-text="name"
                label="Nama Negara"
                dense
                outlined
                color="secondary"
                hide-details
                class="mb-4"
              >
                <template #item="d">
                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center fill-width justify-space-between">
                      {{ d.item.name }}
                      <span>
                        #{{ d.item.country_code }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-model.number="dialogAccount.data.area"
                :items="$store.state.kbri_area"
                item-value="id"
                item-text="name"
                label="Negeri (*)"
                dense
                outlined
                color="secondary"
                hide-details
                class="mb-4"
                @change="dialogAccount.data.area_sub = 0"
              >
                <template #item="d">
                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center fill-width justify-space-between">
                      {{ d.item.name }}
                      <span>
                        #{{ d.item.origin_code }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-model.number="dialogAccount.data.area_sub"
                :disabled="parseInt(dialogAccount.data.area) ? false : true"
                :items="GetDaerah()"
                item-value="id"
                item-text="name"
                label="Daerah (*)"
                dense
                outlined
                color="secondary"
                hide-details
                class="mb-4"
              >
                <template #item="d">
                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center fill-width justify-space-between">
                      {{ d.item.name }}
                      <span>
                        #{{ d.item.code }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-text-field
                v-model="dialogAccount.data.ln_postal_code"
                outlined
                dense
                hide-details
                label="Kode Pos"
                class="mb-4 "
              />
              <v-textarea
                v-model="dialogAccount.data.ln_address"
                label="Alamat Lengkap Luar Negeri (*)"
                outlined
                rows="2"
                dense
                hide-details
                class="mb-4 "
              />
              <v-menu
                v-if="false"
                ref="menuDateArrival"
                v-model="menuDateArrival"
                :close-on-content-click="false"
                :return-value.sync="dialogAccount.data.ln_arrival"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dialogAccount.data.ln_arrival ? (dialogAccount.data.ln_arrival.split('-').length ? $ConvertExcelDate(dialogAccount.data.ln_arrival) : $ConvertExcelDate(dialogAccount.data.ln_arrival)) : dialogAccount.data.ln_arrival"
                    outlined
                    dense
                    hide-details
                    label="Tanggal Kedatangan (dd/mm/yyyy)"
                    class="mb-4"
                    v-bind="attrs"
                    v-on="on"
                    @keyup="!$event.target.value ? '' : ($event.target.value.length === 10 ? $refs.menuDateArrival.save($ConvertExcelDate($event.target.value)) : '')"
                  />
                </template>
                <v-date-picker
                  v-model="dialogAccount.data.ln_arrival"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    class="text-capitalize"
                    @click="menuDateArrival = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    class="text-capitalize"
                    @click="$refs.menuDateArrival.save(dialogAccount.data.ln_arrival)"
                  >
                    Ok
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-text-field
                v-model="dialogAccount.data.ln_arrival"
                placeholder="DD/MM/YYYY"
                outlined
                dense
                hide-details
                label="Tanggal Kedatangan"
                class="mb-4"
              />
              <v-divider class="mb-4" />
              <v-text-field
                v-model="dialogAccount.data.visa"
                outlined
                dense
                hide-details
                label="No. VISA / Izin Tinggal"
                class="mb-4 "
              />
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <v-menu
                    v-if="false"
                    ref="menuDateVisaFrom"
                    v-model="menuDateVisaFrom"
                    :close-on-content-click="false"
                    :return-value.sync="dialogAccount.data.visa_validity_from"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="dialogAccount.data.visa_validity_from ? (dialogAccount.data.visa_validity_from.split('-').length ? $ConvertExcelDate(dialogAccount.data.visa_validity_from) : $ConvertExcelDate(dialogAccount.data.visa_validity_from)) : dialogAccount.data.visa_validity_from"
                        outlined
                        dense
                        hide-details
                        label="Tanggal Awal Berlaku (dd/mm/yyyy)"
                        class="mb-4"
                        v-bind="attrs"
                        v-on="on"
                        @keyup="!$event.target.value ? '' : ($event.target.value.length === 10 ? $refs.menuDateVisaFrom.save($ConvertExcelDate($event.target.value)) : '')"
                      />
                    </template>
                    <v-date-picker
                      v-model="dialogAccount.data.visa_validity_from"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        class="text-capitalize"
                        @click="menuDateVisaFrom = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        class="text-capitalize"
                        @click="$refs.menuDateVisaFrom.save(dialogAccount.data.visa_validity_from)"
                      >
                        Ok
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <v-text-field
                    v-model="dialogAccount.data.visa_validity_from"
                    placeholder="DD/MM/YYYY"
                    outlined
                    dense
                    hide-details
                    label="Tanggal Berlaku Visa"
                    class="mb-4"
                  />
                </v-col>
                <v-col cols="6" class="pl-2">
                  <v-text-field
                    v-model="dialogAccount.data.visa_validity_from"
                    placeholder="DD/MM/YYYY"
                    outlined
                    dense
                    hide-details
                    label="Tanggal Expire Visa"
                    class="mb-4"
                  />
                  <v-menu
                    v-if="false"
                    ref="menuDateVisaTo"
                    v-model="menuDateVisaTo"
                    :close-on-content-click="false"
                    :return-value.sync="dialogAccount.data.visa_validity_to"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="dialogAccount.data.visa_validity_to ? (dialogAccount.data.visa_validity_to.split('-').length ? $ConvertExcelDate(dialogAccount.data.visa_validity_to) : $ConvertExcelDate(dialogAccount.data.visa_validity_to)) : dialogAccount.data.visa_validity_to"
                        outlined
                        dense
                        hide-details
                        label="Tanggal Akhir Berlaku (dd/mm/yyyy)"
                        class="mb-4"
                        v-bind="attrs"
                        v-on="on"
                        @keyup="!$event.target.value ? '' : ($event.target.value.length === 10 ? $refs.menuDateVisaTo.save($ConvertExcelDate($event.target.value)) : '')"
                      />
                    </template>
                    <v-date-picker
                      v-model="dialogAccount.data.visa_validity_to"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        class="text-capitalize"
                        @click="menuDateVisaTo = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        class="text-capitalize"
                        @click="$refs.menuDateVisaTo.save(dialogAccount.data.visa_validity_to)"
                      >
                        Ok
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-divider class="mb-4" />
              <v-select
                v-model="dialogAccount.data.stay_reason"
                :items="[
                  { name: 'Bekerja', value: 'Bekerja' },
                  { name: 'Belajar', value: 'Belajar' },
                  { name: 'DEPENDANT', value: 'DEPENDANT' },
                  { name: 'Lainnya', value: 'Lainnya' }
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
                label="Tujuan Menetap (*)"
              />
              <div v-if="dialogAccount.data.stay_reason === 'Bekerja' || dialogAccount.data.stay_reason === 'Belajar'">
                <v-text-field
                  v-model="dialogAccount.data.stay_name"
                  :label="dialogAccount.data.stay_reason === 'Bekerja' ? 'Nama Perusahaan' : (dialogAccount.data.stay_reason === 'Belajar' ? 'Nama Sekolah' : '')"
                  outlined
                  dense
                  hide-details
                  class="mb-4 "
                />
                <v-textarea
                  v-model="dialogAccount.data.stay_address"
                  :label="dialogAccount.data.stay_reason === 'Bekerja' ? 'Alamat Perusahaan' : (dialogAccount.data.stay_reason === 'Belajar' ? 'Alamat Sekolah' : '')"
                  outlined
                  rows="2"
                  dense
                  hide-details
                  class="mb-4 "
                />
                <v-text-field
                  v-model="dialogAccount.data.stay_data1"
                  :label="dialogAccount.data.stay_reason === 'Bekerja' ? 'Bidang Pekerjaan' : (dialogAccount.data.stay_reason === 'Belajar' ? 'Jenjang Sekolah' : '')"
                  outlined
                  dense
                  hide-details
                  class="mb-4 "
                />
                <v-text-field
                  v-model="dialogAccount.data.stay_data2"
                  :label="dialogAccount.data.stay_reason === 'Bekerja' ? 'Pekerjaan' : (dialogAccount.data.stay_reason === 'Belajar' ? 'Program/Bidang Studi' : '')"
                  outlined
                  dense
                  hide-details
                  class="mb-4 "
                />
                <v-text-field
                  v-if="dialogAccount.data.stay_reason === 'Belajar'"
                  v-model="dialogAccount.data.stay_education_length"
                  outlined
                  dense
                  hide-details
                  label="Lama Pendidikan"
                  class="mb-4 "
                />
                <div v-else-if="dialogAccount.data.stay_reason === 'Bekerja'">
                  <v-text-field
                    v-model="dialogAccount.data.stay_ktkln"
                    outlined
                    dense
                    hide-details
                    label="KTKLN"
                    class="mb-4 "
                  />
                  <v-text-field
                    v-model="dialogAccount.data.stay_pptkis"
                    label="PPTKIS"
                    outlined
                    dense
                    hide-details
                    class="mb-4 "
                  />
                  <v-text-field
                    v-model="dialogAccount.data.stay_agent"
                    label="Agen Penyalur di Luar Negeri"
                    outlined
                    dense
                    hide-details
                    class="mb-4 "
                  />
                </div>
              </div>
              <div class="caption">
                Perkiraan lama menetap
              </div>
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <v-text-field
                    v-model.number="dialogAccount.data.estimated_stay_year"
                    placeholder="0"
                    suffix="Tahun"
                    outlined
                    dense
                    hide-details
                    class="mb-4 "
                  />
                </v-col>
                <v-col cols="6" class="pl-2">
                  <v-text-field
                    v-model.number="dialogAccount.data.estimated_stay_month"
                    placeholder="0"
                    suffix="Bulan"
                    outlined
                    dense
                    hide-details
                    class="mb-4 "
                  />
                </v-col>
              </v-row>
              <v-textarea
                v-model="dialogAccount.data.stay_notes"
                label="Tambahkan Catatan Menetap"
                outlined
                rows="1"
                dense
                hide-details
                class="mb-0"
              />
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <div>
            Kontak Darurat
          </div>
          <v-divider class="my-4" />
          <v-row no-gutters>
            <v-col cols="6" class="pr-2">
              <div class="mb-4 caption font-weight-bold">
                Di Indonesia
              </div>
              <v-text-field
                v-model="dialogAccount.data.emergency"
                label="Nama"
                outlined
                dense
                hide-details
                class="mb-4 "
              />
              <v-text-field
                v-model="dialogAccount.data.emergency_relation"
                label="Hubungan"
                outlined
                dense
                hide-details
                class="mb-4 "
              />
              <v-text-field
                v-model="dialogAccount.data.emergency_email"
                label="Email"
                outlined
                dense
                hide-details
                class="mb-4 "
              />
              <div class="d-flex flex-column flex-sm-row fill-width mb-4">
                <v-autocomplete
                  v-model="dialogAccount.data.emergency_phone_code"
                  :items="$phoneCode()"
                  item-value="dial_code"
                  item-text="dial_code"
                  label="+Dial"
                  dense
                  outlined
                  color="secondary"
                  hide-details
                  class="mr-2 "
                  style="min-width:100px;max-width:100px;width:80px;"
                >
                  <template #item="d">
                    <v-list-item-content>
                      <v-list-item-title>{{ d.item.dial_code + ' ' + d.item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-model.number="dialogAccount.data.emergency_phone"
                  label="No Telepon"
                  dense
                  outlined
                  color="secondary"
                  hide-details
                  class=""
                  @keypress="$NumberOnly"
                  @keyup="dialogAccount.data.emergency_phone = (parseInt(dialogAccount.data.emergency_phone || '') || '')"
                />
              </div>
            </v-col>
            <v-col cols="6" class="pl-2">
              <div class="mb-4 caption font-weight-bold">
                Di Luar Negeri
              </div>
              <v-text-field
                v-model="dialogAccount.data.emergency_ln"
                label="Nama"
                outlined
                dense
                hide-details
                class="mb-4 "
              />
              <v-text-field
                v-model="dialogAccount.data.emergency_relation_ln"
                label="Hubungan"
                outlined
                dense
                hide-details
                class="mb-4 "
              />
              <v-text-field
                v-model="dialogAccount.data.emergency_email_ln"
                label="Email"
                outlined
                dense
                hide-details
                class="mb-4 "
              />
              <div class="d-flex flex-column flex-sm-row fill-width mb-4">
                <v-autocomplete
                  v-model="dialogAccount.data.emergency_phone_code_ln"
                  :items="$phoneCode()"
                  item-value="dial_code"
                  item-text="dial_code"
                  label="+Dial"
                  dense
                  outlined
                  color="secondary"
                  hide-details
                  class="mr-2"
                  style="min-width:100px;max-width:100px;width:80px;"
                >
                  <template #item="d">
                    <v-list-item-content>
                      <v-list-item-title>{{ d.item.dial_code + ' ' + d.item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-model.number="dialogAccount.data.emergency_phone_ln"
                  label="No Telepon"
                  dense
                  outlined
                  color="secondary"
                  hide-details
                  class=""
                  @keypress="$NumberOnly"
                  @keyup="dialogAccount.data.emergency_phone_ln = (parseInt(dialogAccount.data.emergency_phone_ln || '') || '')"
                />
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <div>
            Dokumen
          </div>
          <v-divider class="my-4" />
          <div v-if="!$getImageAll(dialogAccount.data.documents, true).length" class="red--text">
            empty documents
          </div>
          <v-row v-else no-gutters>
            <v-col v-for="(d, iD) in $getImageAll(dialogAccount.data.documents, true)" :key="'document-' + iD" cols="3" class="pa-2">
              <a :href="d.src" target="_blank">
                <v-img
                  :aspect-ratio="1.5/1"
                  :src="d.src"
                  :lazy-src="d.src"
                  width="100%"
                  class="grey lighten-2 rounded-lg"
                />
              </a>
            </v-col>
          </v-row>
          <div class="my-6 pa-4 orange lighten-2 rounded-lg">
            <v-text-field
              v-model="dialogAccount.data.password"
              label="Password Sementara / Reset Password"
              outlined
              dense
              hide-details
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="orange darken-1"
            text
            class="text-capitalize"
            @click="dialogAccount.show = false"
          >
            Batal
          </v-btn>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="DeleteCustomer(dialogAccount.data)"
          >
            Hapus
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            class="text-capitalize"
            @click="ACCOUNT_PROCESS()"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
let searchInterval = null
export default {
  name: 'Customer',
  data: () => ({
    menuDateBirthday: false,
    menuDatePassportExpire: false,
    menuDateArrival: false,
    menuDateVisaFrom: false,
    menuDateVisaTo: false,
    birthdayMenu: false,
    activePicker: null,
    customerFilter: 0,
    menu: false,
    dates: [],
    tableSearchCoordinator: 0,
    headers: [
      {
        text: 'USER ID',
        value: 'id'
      },
      { text: 'Name', value: 'name' },
      { text: 'Negeri', value: 'ln_province' },
      { text: 'Daerah', value: 'ln_city' },
      { text: 'Tujuan Menetap', value: 'stay_reason' },
      { text: 'Passport', value: 'passport_expire' },
      { text: 'Visa', value: 'visa_validity_to' },
      { text: 'Coordinator', value: 'coordinator_name' },
      { text: 'Status', value: 'status' },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: -1,
    options: {
      sortBy: ['created'],
      sortDesc: 'DESC',
      itemsPerPage: 25,
      page: 1
    },
    dialogAccount: {
      show: false,
      data: null
    },
    loading: false
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  },
  watch: {
    birthdayMenu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    customerFilter (v) {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.ACCOUNTS_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    tableSearchCoordinator () {
      this.ACCOUNTS_GET(this.tableSearch)
    },
    options () {
      this.ACCOUNTS_GET(this.tableSearch)
    }
  },
  mounted () {
    this.$store.dispatch('COORDINATORS_GET')
  },
  methods: {
    DeleteCustomer (d) {
      if (d) {
        if (d.name) {
          if (!confirm('Yakin akan hapus data ?')) {
            return false
          }
          this.$store.dispatch('customer/CUSTOMER_DELETE', d.id)
            .then((res) => {
              this.ACCOUNTS_GET(this.tableSearch)
              this.dialogAccount.show = false
            })
        }
      }
    },
    GetDaerah () {
      const data = []
      const n = this.$store.state.kbri_area.find(r => parseInt(r.id) === (this.dialogAccount.data.area))
      if (n) {
        if (n.sub_area) {
          const areas = n.sub_area.split('|-|')
          for (let a = 0; a < areas.length; a++) {
            const area = areas[a] ? areas[a].split('::t') : []
            if (area.length) {
              if (parseInt(area[0])) {
                data.push({
                  id: parseInt(area[0]),
                  area_id: parseInt(n.id),
                  area_name: n.name,
                  name: area[1],
                  code: area[2],
                  total: area[3]
                })
              }
            }
          }
        }
      }
      return data
    },
    saveBirthday (date) {
      this.$refs.birthdayMenu.save(date)
    },
    ACCOUNT_SHOW (account) {
      if (account) {
        account.ln_country = account.ln_country || 'Malaysia'
        account.ln_country_id = parseInt(account.ln_country_id) || 458
        account.estimated_stay_year = 0
        account.estimated_stay_month = 0
        account.password = ''
        if (parseInt(account.estimated_stay)) {
          account.estimated_stay_year = Math.floor((parseInt(account.estimated_stay) || 0) / 365) || 0
          account.estimated_stay_year = account.estimated_stay_year < 0 ? 0 : account.estimated_stay_year
          const restOfMonthDecimal = ((parseInt(account.estimated_stay) || 0) / 365) - account.estimated_stay_year
          account.estimated_stay_month = Math.ceil(restOfMonthDecimal * 12) || 0
          account.estimated_stay_month = account.estimated_stay_month < 0 ? 0 : account.estimated_stay_month
        }
        account.birthday_date = account.birthday_date ? this.$ConvertExcelDate(account.birthday_date) : ''
        account.ln_arrival = account.ln_arrival ? this.$ConvertExcelDate(account.ln_arrival) : ''
        account.passport_expire = account.passport_expire ? this.$ConvertExcelDate(account.passport_expire) : ''
        account.visa_validity_from = account.visa_validity_from ? this.$ConvertExcelDate(account.visa_validity_from) : ''
        account.visa_validity_to = account.visa_validity_to ? this.$ConvertExcelDate(account.visa_validity_to) : ''
        this.dialogAccount.data = Object.assign({}, account)
      } else {
        this.dialogAccount.data = {
          id: null,
          nik: '',
          passport: '',
          passport_expire: '',
          name: '',
          marriage_status: '', // Kawin, Belum Kawin, Cerai Hidup, Cerai Mati
          religion: '', // Islam, Kristen, Katholik, Budha, Hindu, Konghuchu, Kepercayaan, Lainnya
          birthday_place: '',
          birthday_date: '',
          phone_code: '+60',
          phone: '',
          email: '',
          address: '',
          documents: '[]',

          sex: '', // Laki-laki / Perempuan

          ln_arrival: '',
          ln_address: '',
          ln_country: '',
          ln_country_id: 458,
          ln_province: '',
          ln_city: '',
          ln_postal_code: '',
          area: 0,
          area_sub: '',

          visa: '',
          visa_validity_from: '',
          visa_validity_to: '',
          estimated_stay: 0,
          estimated_stay_year: 0,
          estimated_stay_month: 0,
          stay_reason: '', // Bekerja, Belajar, Lainnya
          stay_description: '',
          stay_name: '', // nama sekolah, nama perusahaan
          stay_address: '', // alamat sekolah, alamat perusahaan
          stay_data1: '', // Bidang Kerja, Jenjang Sekolah
          stay_data2: '', // Pekerjaan, Program Studi
          stay_education_length: '', // If Belajar
          stay_pptkis: '', // If Pekerjaan
          stay_agent: '', // If Pekerjaan / Agen Penyalur di Luar Negeri
          stay_notes: '',
          stay_ktkln: '', // If Pekerjaan (Kartu Tenaga Kerja Luar Negeri)

          emergency_ln: '',
          emergency_relation_ln: '',
          emergency_email_ln: '',
          emergency_phone_code_ln: '+60',
          emergency_phone_ln: '',

          emergency: '',
          emergency_relation: '',
          emergency_email: '',
          emergency_phone_code: '+62',
          emergency_phone: '',

          img: '',
          img_nik: '',
          img_passport: '',
          password: '',
          rauth: '',
          verified_email: 0,
          verified_phone: 0,
          verified_email_code: '',
          verified_phone_code: '',
          fcm_ids: '',
          status: 1,
          role: 'customer',
          notes: ''
        }
      }
      this.dialogAccount.show = true
    },
    ACCOUNTS_GET (q) {
      const status = this.tableSearchStatus > -1 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const fromTo = this.GetDates(this.dates)
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('customer/CUSTOMER_SEARCH', query + (q ? ('&q=' + q) : '') + ('&coordinator=' + (parseInt(this.tableSearchCoordinator) || 0)) + fromTo + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : ''))
        .then((res) => {
          if (res.status) {
            this.table = res.data.data.data
            this.tableTotal = parseInt(res.data.data.total) || 0
          } else {
            this.table = []
            this.tableTotal = 0
          }
          this.tableLoading = false
        })
    },
    ACCOUNT_PROCESS () {
      if (this.loading) {
        this.$store.dispatch('TOAST', { show: true, message: 'Mohon tunggu!' })
        return false
      }
      const processedAccount = Object.assign({}, this.dialogAccount.data)
      if (!processedAccount.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukkan nama yang valid!' })
        return false
      } else if (processedAccount.name.length < 3) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukkan nama yang valid!' })
        return false
      }
      if (!processedAccount.birthday_place) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukkan tempat lahir yang valid!' })
        return false
      } else if (processedAccount.birthday_place.length < 3) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukkan tempat lahir yang valid!' })
        return false
      }
      if (!this.$ValidateExcelDate(processedAccount.birthday_date)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukkan tanggal lahir yang valid!' })
        return false
      } else {
        processedAccount.birthday_date = this.$ConvertExcelDate(processedAccount.birthday_date)
      }
      if (!processedAccount.nik && !processedAccount.passport) {
        this.$store.dispatch('TOAST', { show: true, message: 'Mohon isi salah satu NIK atau Passport!' })
        return false
      }
      if (processedAccount.passport_expire && !this.$ValidateExcelDate(processedAccount.passport_expire)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Format tanggal passport expire salah!' })
        return false
      } else if (processedAccount.passport_expire) {
        processedAccount.passport_expire = this.$ConvertExcelDate(processedAccount.passport_expire)
      }
      // if (!this.$validateEmail(processedAccount.email)) {
      //   this.$store.dispatch('TOAST', { show: true, message: 'Email tidak valid!' })
      //   return false
      // }
      if (!processedAccount.phone) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukkan no telepon yang valid!' })
        return false
      } else if (processedAccount.phone.length < 4) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukkan no telepon yang valid!' })
        return false
      }
      if (!processedAccount.ln_address) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukkan alamat luar negeri yang valid!' })
        return false
      } else if (processedAccount.ln_address.length < 7) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukkan alamat luar negeri yang valid!' })
        return false
      }
      if (processedAccount.ln_arrival && !this.$ValidateExcelDate(processedAccount.ln_arrival)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Format tanggal kedatangan salah!' })
        return false
      } else if (processedAccount.ln_arrival) {
        processedAccount.ln_arrival = this.$ConvertExcelDate(processedAccount.ln_arrival)
      }
      if (processedAccount.visa_validity_from && !this.$ValidateExcelDate(processedAccount.visa_validity_from)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Format tanggal mulai berlaku visa salah!' })
        return false
      } else if (processedAccount.visa_validity_from) {
        processedAccount.visa_validity_from = this.$ConvertExcelDate(processedAccount.visa_validity_from)
      }
      if (processedAccount.visa_validity_to && !this.$ValidateExcelDate(processedAccount.visa_validity_to)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Format tanggal akhir berlaku visa salah!' })
        return false
      } else if (processedAccount.visa_validity_to) {
        processedAccount.visa_validity_to = this.$ConvertExcelDate(processedAccount.visa_validity_to)
      }
      if (!processedAccount.stay_reason) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tujuan menetap belum dipilih!' })
        return false
      } else if (processedAccount.stay_reason.length < 4) {
        this.$store.dispatch('TOAST', { show: true, message: 'Tujuan menetap belum dipilih!' })
        return false
      }

      processedAccount.ln_country = ''
      const country = this.$phoneCode().find(r => parseInt(r.country_code) === parseInt(processedAccount.ln_country_id))
      if (country) {
        processedAccount.ln_country = country.name || ''
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama Negara belum dipilih!' })
        return false
      }

      const areas = this.GetDaerah()
      const selectedArea = areas.find(r => parseInt(r.id) === parseInt(processedAccount.area_sub))
      if (!parseInt(processedAccount.area)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama Negeri/Provinsi belum dipilih!' })
        return false
      } else if (selectedArea) {
        processedAccount.ln_province = selectedArea.area_name || ''
      }
      if (!parseInt(processedAccount.area_sub)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama Daerah belum dipilih!' })
        return false
      } else if (selectedArea) {
        processedAccount.ln_city = selectedArea.name || ''
      }

      processedAccount.estimated_stay = (parseInt(processedAccount.estimated_stay_year) || 0) * 365 // estimate days in year
      processedAccount.estimated_stay += (parseInt(processedAccount.estimated_stay_month) || 0) * 30 // estimate days in month
      processedAccount.estimated_stay = parseInt(processedAccount.estimated_stay) || 0
      processedAccount.role = 'customer'
      if (!processedAccount.password) {
        delete processedAccount.password
      }
      this.loading = true
      this.$store.dispatch('customer/CUSTOMER_PROCESS_BY_ADMIN', processedAccount)
        .then((res) => {
          this.loading = false
          if (res.status) {
            if (res.data.data.status) {
              this.ACCOUNTS_GET(this.tableSearch)
              this.dialogAccount.show = false
              this.$store.dispatch('TOAST', { show: true, message: processedAccount.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    GetDates () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    }
  }
}
</script>
